export const WORDS = [
  'meter',
  'think',
  'vuser',
  'timer',
  'check',
  'bytes',
  'alert',
  'delay',
  'trace',
  'start',
  'spike',
  'error',
  'watch',
  'stack',
  'patch',
  'scale',
  'tempo',
  'relic',
  'kafka',
  'flink',
  'graph',
  'alert',
  'slack',
  'cache',
  'linux',
  'issue',
  'tests',
  'while',
  'regex',
  'debug',
  'setup',
  'group',
  'statd',
  'cloud',
  'azure',
  'sleep',
  'ports',
  'image',
  'hosts',
  'chart',
  'index',
  'shape',
  'spawn',
  'users',
  'tasks',
  'stats',
  'build',
  'queue',
  'shell',
  'chaos',
  'proxy',
  'metric',
  'trend',
  'batch', 
  'gauge', 
  'pause',
]
